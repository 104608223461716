import { RequestStatus } from '@/graphql/types';
import { assertNever } from '@/utils/types/types';
import { FaArrowAltCircleRight } from 'react-icons/fa';
import { MdCancel, MdCheckCircle, MdStopCircle } from 'react-icons/md';
import { RequestStatusAppearanceInfo } from '../types/requestStatusLabel';

export const pendingStatus: RequestStatusAppearanceInfo = {
  value: RequestStatus.Pending,
  label: '依頼中',
  color: 'blue.500',
  icon: FaArrowAltCircleRight,
  defaultLabelKey: RequestStatus.Pending,
};

const approvedStatus: RequestStatusAppearanceInfo = {
  value: RequestStatus.Approved,
  label: '承認済',
  color: 'success',
  icon: MdCheckCircle,
  defaultLabelKey: RequestStatus.Approved,
};

const rejectedStatus: RequestStatusAppearanceInfo = {
  value: RequestStatus.Rejected,
  label: '却下',
  color: 'warning.500',
  icon: MdCancel,
  defaultLabelKey: RequestStatus.Rejected,
};

export const canceledStatus: RequestStatusAppearanceInfo = {
  value: RequestStatus.Canceled,
  label: '取り下げ',
  color: 'neutral.500',
  icon: MdStopCircle,
  defaultLabelKey: RequestStatus.Canceled,
};

export const allStatusLabelOptions = [
  pendingStatus,
  approvedStatus,
  rejectedStatus,
  canceledStatus,
];

export const statusLabelOptionsForMember = [pendingStatus, canceledStatus];

export const getRequestStatus = (status: RequestStatus): RequestStatusAppearanceInfo => {
  switch (status) {
    case 'pending':
      return pendingStatus;
    case 'approved':
      return approvedStatus;
    case 'rejected':
      return rejectedStatus;
    case 'canceled':
      return canceledStatus;
    default:
      return assertNever(status);
  }
};
