import { RequestStatus } from '@/graphql/types';
import useTranslation from '@/utils/i18n/useTranslation';
import { useCallback } from 'react';
import { RequestStatusAppearanceInfo } from '../types/requestStatusLabel';
import { getRequestStatus } from '../utils/getRequestStatus';

type ReturnType = {
  getStatusAppearanceInfo(value: RequestStatus): RequestStatusAppearanceInfo;
  getStatusLabel(value: RequestStatus): string;
};

export const useRequestStatus = (): ReturnType => {
  const { t } = useTranslation();

  const getStatusLabel = useCallback(
    (value: RequestStatus) => {
      const { defaultLabelKey, label } = getRequestStatus(value);

      return t(`status.request.${defaultLabelKey}`, label);
    },

    [t]
  );

  const getStatusAppearanceInfo = useCallback(
    (value: RequestStatus) => {
      const status = getRequestStatus(value);
      const label = getStatusLabel(value);

      return { ...status, label };
    },
    [getStatusLabel]
  );

  return {
    getStatusLabel,
    getStatusAppearanceInfo,
  };
};
